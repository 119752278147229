import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, withPrefix } from "gatsby";
import { v4 as uuidv4 } from "uuid";
import { useSetRecoilState, useResetRecoilState } from "recoil";

import Layout from "../layout";
import { introStepAtom, selectedItemAtom, showCartAtom } from "../utils/atoms";
import bg from "../images/petricollarcollection_23.jpg";

function HomePage({ location }) {
  const setSelectedItem = useSetRecoilState(selectedItemAtom);
  const setIntroStep = useSetRecoilState(introStepAtom);
  const setShowCart = useSetRecoilState(showCartAtom);

  // reset selection
  setSelectedItem(null);
  setIntroStep("intro-name");
  setShowCart(false);

  return (
    <Layout location={location}>
      <>
        <Helmet title="Stuntpuppy" />
        <main className="h-screen relative">
          {/* <img src={bg} alt="" className="absolute inset-0 object-cover" /> */}
          <div
            className="absolute inset-0 bg-cover"
            style={{ backgroundImage: `url(${bg})` }}
          />
        </main>
      </>
    </Layout>
  );
}

export default HomePage;
